<script>
import BOGen from '@helper/BOGen';
export default {
  name: "FileManager",
  props:{
    inline: Boolean,
    type: String,
  },
  data(){
    return {
      // Configuration
      params: {},
      // Var
      loadingLoadmore: false,
      show: false,
      loaded: false,
      selectedFolder: {},
      paths: [],
      filter: {search:"",skip:0},
      callback: null,
      mrValidation: {}
    }
  },
  computed:{
    page(){ return this.$root.page },
    // Configuration
    explore(){ return this.params.explore || false },
    allowedFileTypes(){ return this.params.allowedFileTypes || "jpg,jpeg,png,gif,docs,pdf,doc,xls,xlsx,txt" },
    multiple(){ return this.params.multiple || false },
    // Variable
    setPath(){
      var v = this.selectedFolder
      if(!v.dirname) return ""
      return v.dirname == "." ? v.filename.replaceAll(/\./,"") : v.filename.replaceAll(/()./,"")+"/"+v.filename.replaceAll(/\./,"");
    },
    dataPaths(){
      if(!this.filter.search) return this.paths;
      return this.paths.filter((x)=>{ return x.basename.toLowerCase().indexOf(this.filter.search.toLowerCase()) > -1 })
    },
    selectedFiles(){
      return this.paths.filter((x)=>{ return x.selected === true })
    }
  },
  mounted(){
    global.FileManager = this
    if(this.inline){
      this.params = {search:""}
      this.refreshPath()
    }
    if(this.type){
      this.selectedFolder = {dirname:".",filename:this.type}
    }
  },
  watch:{
    'params.search'(v){
      Gen.delay(()=>{
        this.$set(this.filter, "skip", 0)
        this.refreshPath()
      }, 400)
    }
  },
  methods:{
    open(cb, params = {}){
      this.params = params
      if(params.type){
        this.selectedFolder = {dirname:".",filename:params.type}
      }
      this.show = true
      this.refreshPath()
      this.callback = cb
      setTimeout(()=>{
        $("#filemanager_scroll").off();
        $("#filemanager_scroll").scroll(()=>{
          if(this.loadingLoadmore||this.loadmoreDone) return;
          var scrollPosition = $("#filemanager_scroll").scrollTop();
          var loadmoreTop = $("#filemanager_scroll .loadmore").offset().top-$(window).height()-$(window).scrollTop()+$("#filemanager_scroll").scrollTop();
          if($(".loadmore").length){
            if(scrollPosition>(loadmoreTop-350)){
              this.loadingLoadmore = true
              this.filter.skip = this.paths.length
              this.refreshPath((resp)=>{
                this.loadingLoadmore = false
                if(!resp.length) return this.loadmoreDone = true
                this.paths = this.paths.concat(resp)
              })
            }
          }
        })
      }, 300)
    },
    close(){
      if(this.inline){
        return window.close()
      }
      this.paths = []
      this.selectedFolder = {}
      this.show = false
    },
    delImg(v) {
      swal({
        title: "Delete Image?",
        text: "Image may have been used in another article!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((ok) => {
        if (!ok) return;
        BOGen.apirest("/delete-manager", {
          path: v.targetfile,
          id: v.fm_id
        }, (err, resp) => {
          if (err) return
          swal("Successfully deleted image!", {
            icon: "success",
          });
          this.refreshPath()
        })
      })
    },
    refreshPath(cb){
      if(!cb||true) this.loaded = true
      BOGen.apirest("/uploader-manager", {path:this.setPath,skip:this.filter.skip,search:this.params.search}, (err, resp)=>{
        if(err) return
        if(cb) return cb(resp)
        // this.paths = resp.filter((x)=>{ 
        //   return (x.extension ? this.allowedFileTypes.indexOf(x.extension) > -1 : true) && (this.explore ? true : x.basename != "Up")
        // })
        this.paths = resp
        this.loaded=false
        // this.filter.search = ""
        if(this.inline){
          this.show = true
          this.params = {explore:true,search:this.params.search}
        }
      })
    },
    refreshPathClear(){
      this.filter.skip = 0
      this.refreshPath()
    },
    fileAction(k,v){
      if(!v.extension){
        this.selectedFolder = v
        this.refreshPath()
      }else{
        if(!this.multiple){
          this.paths.forEach((v)=>{ this.$set(v,'selected', false) })
        }
        this.$set(v, 'selected', !v.selected);
      }
    },
    choose(){
      if(!this.selectedFiles.length) return
      var files = this.multiple ? this.selectedFiles : this.selectedFiles[0]
      if(this.callback) this.callback(files)
      if(this.inline){
        window.opener.CKEDITOR.tools.callFunction(this.$route.query.CKEditorFuncNum, files.targetfile);
      }
      this.close()
    }
  }
};
</script>

<template>
  <div id="FileManager" :class="{inline:inline}" v-if="show">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-title">
          Image Gallery
          <span v-if="setPath">- {{setPath}}</span>
        </div>
        <div class="panel-navtool">
          <div class="search_input">
            <input type="text" v-model="params.search" placeholder="Type to filter" class="form-control">
          </div>
        </div>
        <div class="panel-action">
          <a href="javascript:;" @click="close()"><i class="ti-close"></i></a>
        </div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body explorer" id="filemanager_scroll">
          <div class="row" v-if="loaded">
            <div class="text-center">
              <img :src="page.assets('bo_images','loader-ripple.gif')">
            </div>
          </div>
          <div class="row" v-else>
            <div 
              v-for="(v,k) in dataPaths" :key="k"
              @click="fileAction(k,v)"
              @dblclick="choose"
              :class="{'col-sm-12':1,'file-item':1,selected:v.selected}">
              <div class="preview">
                <div class="middleLine"></div>
                <VImg class="thumb" :src="v.thumb"></VImg>
                <div class="icon" v-if="!v.thumb">
                  <i v-if="v.basename=='Up'" class="icon-arrow-up"></i>
                  <i v-else-if="v.extension" class="ti-file"></i>
                  <i v-else class="ti-folder"></i>
                </div>
                <div v-if="v.thumb" @click="delImg(v)" class="del_img"><i class="icon-trash"></i></div>
              </div>
              <span class="filename">{{v.basename.limitChar(40)}}</span>
            </div>
            <h3 v-if="!this.dataPaths.length">
              Image gallery not found
            </h3>
            <div class="col-sm-12 loadmore">
              <LoadingSpinner v-if="loadingLoadmore" light></LoadingSpinner>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <div class="row">
            <div class="col-md-1 col-xs-12">
              <a class="btn btn-info" @click="choose" :disabled="!selectedFiles.length">Choose</a>
            </div>
            <div class="col-md-11 col-xs-12">
              <Uploader name="FileManagerInput" @response="refreshPathClear()" :type="setPath"></Uploader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#FileManager{
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 25px 50px;
  background: rgba(0,0,0,0.4);
  &.inline{
    padding: 0px;
  }
}
.panel{
  height: 100%;
  background: #fff;
  .panel-wrapper{
    height: calc(100% - 160px);
  }
}
.panel-heading{
  position: relative;
  color: #2b2b2b;
  border-radius: 0px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 20px 25px;
  .panel-title{
    display: inline-block;
    width: 300px;
  }
  .panel-navtool{
    display: inline-block;
    width: calc(100% - 300px - 50px);
    text-align: right;
    > div{ display: inline-block; }
    .search_input{
      width: 200px;
    }
  }
  .panel-action{
    position: absolute;
    top: 25px;
    right: 25px;
  }
}
.explorer{
  height: 100%;
  overflow-y: auto;
}
.file-item{
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  margin-right: 5px;
  width: calc(16.667% - 5px);
  &:hover{
    background: rgba(3,169,243,0.1);
  }
  &.selected{
    background: rgba(3,169,243,0.2);
    border: 1px solid rgba(3,169,243,0.4);
  }
  .preview{
    .middleLine{
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 1px;
      margin-left: -1px;
    }
    height: 80px;
    .thumb{
      display: inline-block;
      vertical-align: middle;
      max-width: 80px;
      max-height: 80px;
    }
    .icon{
      display: inline-block;
      i{
        font-size: 70px;
      }
    }
  }
  .filename{
    display: inline-block;
    margin-top: 5px;
    height: 40px;
  }
  .loading_img{margin-top: 40%;}
}
.del_img {
  width: 25px;
  height: 25px;
  color: #fff;
  background-color: #ff0e0e;
  border-radius: 100%;
  line-height: 24px;
  position: absolute;
  top: 6%;
  right: 20%;
  opacity: 0;
}
.file-item:hover .del_img{opacity: 1;}

@media (max-width: 767px) {
  .file-item{
    width: auto;
  }
}
</style>
